import React from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { UserSubscriptions, UserInvoices } from '../../../../ui/';
import { refreshAccountStore } from '../../../../data/refreshAccountStore';

import { SubscriptionStage } from '../../../../ui/UserSubscriptions/components';

// const handleRefreshAccountStore = async () => {
//   await refreshAccountStore();
// }

const Subscriptions = () => {
  return (
    <>
      <Grid container spacing={1}>
        {/* <Grid xs={12}>
          <Box mx={['.47rem']} my={['1rem']}>
            <Button onClick={handleRefreshAccountStore}>Refresh Account Store</Button>
          </Box>
        </Grid> */}

        <Grid xs={12}>
          <Box mx={['.47rem']} my={['1rem']}>
            <UserSubscriptions />
          </Box>
        </Grid>

        <Grid xs={12}>
          <Box mx={['.47rem']} my={['1rem']}>
            <SubscriptionStage />
          </Box>
        </Grid>

        <Grid xs={12}>
          <Box mx={['.47rem']} my={['1rem']}>            
            <UserInvoices />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Subscriptions;
