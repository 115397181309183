/********************************************************************************
 * HOOK: USE LOGIN EVENTS
 * ----------------------------------------------------------------------------
 * Login events allow us to listen for login events and perform actions when
 * based on various states and events.
 * Login: load data
 * Logout: unload data
 * etc.
 * *****************************************************************************/

import { useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';
import { navigate } from 'gatsby';
import { loginRedirect } from '../elo.config';
import posthog from 'posthog-js';

/************************************************************************
 * ACCOUNT STORE
 * -----------------------------------------------------------------------
 * **********************************************************************/
import { useAccountStore } from '../store';

/************************************************************************
 * DATA FUNCTIONS
 * -----------------------------------------------------------------------
 * Import functions necessary to retrieve data from the ELO API.
 * **********************************************************************/
import { getUserAccount, getUserConfiguration, getLineItems, initializeInvoices } from '../data';
import { post } from 'aws-amplify/api';

/************************************************************************
 * USE LOGIN EVENTS
 * -----------------------------------------------------------------------
 * This is the main hook that listens for login events and performs
 * actions based on the event.
 * **********************************************************************/
export const useLoginEvents = (): void => {
  // * DESTRUCTURE THE updateAccountState ACTION FROM THE ACCOUNT STORE
  const updateAccountState = useAccountStore(state => state.updateAccountState);
  const reset = useAccountStore(state => state.reset);

  useEffect(() => {
    const authEvent = Hub.listen('auth', data => {
      // * PAYLOAD FROM HUB EVENT
      const { payload } = data;

      /**********************************************************************
       * * HANDLE SIGNED IN EVENT
       * --------------------------------------------------------------------
       * This async function sets the loading data to true, then retrieves
       * data from the ELO API and loads the central store in an async manner,
       * setting the loading data to false once the data has been loaded, and
       * then finally redirects the user to the account page.
       * ********************************************************************/
      const handleSignedIn = async () => {
        // * SET LOADING DATA TO TRUE
        updateAccountState(state => {
          state.loadingData = true;
        });

        // * OPTIMIZE THE DATA RETRIEVAL BY USING PROMISE.ALL
        // This will run all three async requests at the same time and wait for all of them to complete before continuing.
        const [
          userConfiguration,
          userAccount,
          lineItems,
          // purchasableSubscriptions
        ] = await Promise.all([
          getUserConfiguration(), // * GET USER CONFIGURATION
          getUserAccount(), // * GET USER ACCOUNT
          getLineItems(), // * GET LINE ITEMS
        ]);
        
        posthog.identify(userAccount?.id, {})

        // * UPDATE THE STORE
        updateAccountState(state => {
          state.authenticated = true;
          state.userAccount = userAccount ?? null;
          state.userConfiguration = userConfiguration?.userConfiguration ?? null;
          state.lineItems = lineItems ?? null;
          state.loadingData = false; // * SET LOADING DATA TO FALSE
        });

        // * POST PROCESSING AFTER DATA HAS BEEN LOADED        
        initializeInvoices(); // * INITIALIZE INVOICES

        // * NAVIGATE TO THE ACCOUNT PAGE
        navigate(loginRedirect ?? '/account');
      };

      /**********************************************************************
       * * HANDLE SIGNED OUT EVENT
       * --------------------------------------------------------------------
       * When a user signs out, reset the store to the initial state.
       * ********************************************************************/
      const handleSignedOut = async () => {
        posthog.reset();
        reset();
      };

      /**********************************************************************
       * * HANDLE SIGNED OUT EVENT
       * --------------------------------------------------------------------
       * When a user signs out, reset the store to the initial state.
       * ********************************************************************/
      const handleTokenRefresh = async () => {
        return null;
      };

      /**********************************************************************
       * * RETURN EARLY WITH NO PAYLOAD
       * --------------------------------------------------------------------
       * ********************************************************************/
      if (!payload) return;

      /**********************************************************************
       * * SWITCH ON PAYLOAD EVENT
       * --------------------------------------------------------------------
       * Below are the various events that can be triggered by the auth hub.
       * Within each event we can perform various actions based on the event.
       * ********************************************************************/
      switch (payload.event) {
        /**********************************************************************
         * * CASE: SIGNED IN
         * --------------------------------------------------------------------
         * When the user is signed in, we want to load the data from the ELO
         * API and load it into the central store.
         * ********************************************************************/
        case 'signedIn':
          handleSignedIn();
          break;
        case 'signedOut':
          handleSignedOut();
          break;
        case 'tokenRefresh':
          handleTokenRefresh();
          break;
        default:
          break;
      }
    });

    return authEvent;
  }, []);
};

export default useLoginEvents;
