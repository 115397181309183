import React, { useState } from 'react';
import { PregnancyStage } from '../../../../types/API';
import {
  Box,
  Typography,
  Button,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
  Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ToTrimester1 from './ToTrimester1/ToTrimester1';
import ToPreconception from './ToPreconception/ToPreconception';
import { updateStage, getUserConfiguration } from '../../../../data';

import { Accordian } from '../../../../apps/account/components';

// * Import Account Store
import { useAccountStore } from '../../../../store';
import { get } from 'lodash';

const SubscriptionStage = () => {
  /**********************************************************************
   * USE ACCOUNT STORE
   * --------------------------------------------------------------------
   * ********************************************************************/
  const pregnancyStage = useAccountStore(state => state.userAccount?.preferences?.pregnancyStage);
  const userAccount = useAccountStore(state => state.userAccount);
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  /**********************************************************************
   * LOCAL COMPONENT STATE
   * --------------------------------------------------------------------
   * Some local state to manage the pregnancy stage outside of the global
   * state is necessary. This is because we don't want to update the
   * global state until the user has confirmed that they want to update.
   * This will require some prop drilling but it's a small price to pay
   * for the flexibility of the global state.
   * ********************************************************************/
  const [value, setValue] = useState<PregnancyStage | undefined | null>(pregnancyStage);
  const [toPreconception, setToPreconception] = useState<boolean>(false);
  const [toTrimester1, setToTrimester1] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  /**********************************************************************
   * DISABLE UPDATE BUTTON
   * --------------------------------------------------------------------
   * If the current pregnancy state is eqivalent to the value, then the
   * update button should be disabled.
   * ********************************************************************/
  const disableUpdateButton = () => {
    // console.log('stage: ', pregnancyStage, ' value: ', value);
    if (pregnancyStage === value) {
      return true;
    }
    // console.log('Enable Button ', pregnancyStage, value);
    return false;
  };

  /**********************************************************************
   * All Accounts Cancelled
   * --------------------------------------------------------------------
   * Function to determin if all subscriptions are cancelled
   * ********************************************************************/
  const allAccountsCancelled = () => {
    if (!userAccount?.subscriptions?.items) return false;
    if (userAccount?.subscriptions?.items.length <= 1) return false;
    return userAccount?.subscriptions?.items.every(
      subscription => subscription?.cancellationRequestedAt !== null || undefined
    );
  };

  const allCancelled = allAccountsCancelled();
  // console.log('All Cancelled: ', allCancelled);

  /**********************************************************************
   * HANDLE CHANGE
   * --------------------------------------------------------------------
   * This function is responsible for updating the local state with the
   * new pregnancy stage.
   * ********************************************************************/
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as PregnancyStage);
  };

  /**********************************************************************
   * HANDLE UPDATE TO PRECONCEPTION
   * --------------------------------------------------------------------
   * This function will handle the update to the preconception stage.
   * This will be replaced with a modal flow later.
   * ********************************************************************/
  const handleUpdateToPreconception = async () => {
    setLoading(true);
    try {
      // console.log('Updating to preconception');
      await updateStage(PregnancyStage.preconception);
      await getUserConfiguration();
      setLoading(false);
    } catch (error) {
      console.error('Error updating pregnancy stage', error);
      setLoading(false);
    }
  };

  /**********************************************************************
   * HANDLE UPDATE STAGE
   * --------------------------------------------------------------------
   * This function is responsible for updating the global state with the
   * new pregnancy stage.
   * ********************************************************************/
  const handleUpdateStage = () => {
    if (!value) return;
    switch (value) {
      case PregnancyStage.pregnantTrimester1:
        setToTrimester1(true);
        break;

      /**********************************************************************
       * TEMPORARILY MUTATE THE GLOBAL STATE HERE
       * --------------------------------------------------------------------
       * Until we have a decision on how to handle changing the pregnancy
       * stage back to pre-conception, we will temporarily mutate the global
       * state here. This will be replaced with a modal flow later.
       * ********************************************************************/
      case PregnancyStage.preconception:
        // handleUpdateToPreconception();
        setToPreconception(true);
        break;
      default:
        break;
    }
  };

  if (!pregnancyStage) return null;

  /********************************************************************************
   * TO TRIMESTER 1
   * -----------------------------------------------------------------------------
   * ******************************************************************************/
  if (toTrimester1) {
    return (
      <Modal open={toTrimester1}>
        <ToTrimester1 cancel={setToTrimester1} />
      </Modal>
    );
  }

  /********************************************************************************
   * PRECONCEPTION SUPPORT
   * -----------------------------------------------------------------------------
   * ******************************************************************************/
  if (toPreconception) {
    return (
      <Modal open={toPreconception}>
        <ToPreconception cancel={setToPreconception} />
      </Modal>
    );
  }

  /********************************************************************************
   * FUNCTION: GET STAGE
   * -----------------------------------------------------------------------------
   * Just a quick function to convert the pregnancy stage to a human readable and
   * friendly format.
   * ******************************************************************************/
  const getStage = () => {
    switch (pregnancyStage) {
      case PregnancyStage.preconception:
        return 'Create';
      case PregnancyStage.pregnantTrimester1:
        return 'Form';
      case PregnancyStage.pregnantTrimester2:
        return 'Flourish';
      case PregnancyStage.pregnantTrimester3:
        return 'Flourish';
      default:
        return 'Nurture';
    }
  };

  return (
    <>
      <Stack spacing={4}>
        <Accordian title={`Your Stage: ${getStage()}`} expanded={false}>
          <Grid
            container
            spacing={2}
            sx={
              {
                // background: '#F8F9F2',
                // borderRadius: '8px',
                // border: '2px solid #E5E5E5',
              }
            }
          >
            {/* <Grid xs={12}>
            <Box display={'flex'} justifyContent={'space-between'} my={['1rem']} px={['.47rem']}>
              <Typography variant="h4">Reproductive Stage</Typography>
            </Box>
            <Divider sx={{ mt: ['1.4rem'] }} />
          </Grid> */}

            {allCancelled ? (
              <>
                <Grid xs={12}>
                  <Box p={['1.47rem']}>
                    <Typography variant="body2">
                      If you would like to restart a Luminary Vitamins subscription, please contact
                      Customer Care at{' '}
                      <a href="mailto:help@luminaryvitamins.com">help@luminaryvitamins.com</a>. Our
                      team is on standby to support your nutritional needs throughout your
                      reproductive journey.
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12}>
                  <Box p={['1.47rem']}>
                    <Stack spacing={2}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                      >
                        {pregnancyStage === PregnancyStage.preconception && (
                          <>
                            <FormControlLabel
                              value={PregnancyStage.preconception}
                              control={<Radio />}
                              label={<Typography variant="body1">Create (prenatal)</Typography>}
                            />
                            <FormControlLabel
                              value={PregnancyStage.pregnantTrimester1}
                              control={<Radio />}
                              label={
                                <Typography variant="body1">Form (early pregnancy)</Typography>
                              }
                            />
                          </>
                        )}

                        {pregnancyStage === PregnancyStage.pregnantTrimester1 && (
                          <FormControlLabel
                            value={PregnancyStage.pregnantTrimester1}
                            control={<Radio />}
                            label={<Typography variant="body1">Form (early pregnancy)</Typography>}
                          />
                        )}

                        {pregnancyStage === PregnancyStage.pregnantTrimester2 && (
                          <FormControlLabel
                            value={PregnancyStage.pregnantTrimester2}
                            control={<Radio />}
                            label={
                              <Typography variant="body1">Flourish (late pregnancy)</Typography>
                            }
                          />
                        )}

                        {pregnancyStage === PregnancyStage.pregnantTrimester3 && (
                          <FormControlLabel
                            value={PregnancyStage.pregnantTrimester3}
                            control={<Radio />}
                            label={
                              <Typography variant="body1">Flourish (late pregnancy)</Typography>
                            }
                          />
                        )}

                        {/* {pregnancyStage !== PregnancyStage.preconception && (
                          <FormControlLabel
                            value={PregnancyStage.preconception}
                            control={<Radio />}
                            label={<Typography variant="body1">I am no longer pregnant</Typography>}
                          />
                        )} */}
                      </RadioGroup>

                      <Typography variant="body2">
                        We provide nutritional support for every stage of your path to parenthood.
                        If your status changes, we’ll send you a new product formulated to support
                        the exact stage you’re in.
                      </Typography>

                      {pregnancyStage === PregnancyStage.preconception && (
                        <Box pt={['1.47rem']}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateStage}
                            disabled={disableUpdateButton() || loading}
                          >
                            {loading ? 'Updating...' : 'Update'}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Accordian>
      </Stack>
    </>
  );
};

export default SubscriptionStage;
