/**************************************************************************************
 * QUIZ PAGE / QUESTIONNAIRE / TYPEFORM / ASSESSMENT
 * ------------------------------------------------------------------------------------
 *
 * Call it what you will, this page presents the user with a series of questions that
 * will determine their custom formula. The questions are presented in a typeform embed
 * that is loaded into the page. The typeform embed is loaded into the page using the
 * typeform embed-react package. The typeform embed-react package is a wrapper for the
 * typeform embed SDK. The typeform embed SDK is a wrapper for the typeform API.
 *
 * Typeform embed requires a typeform ID to load the form. The typeform ID is passed in
 * as a prop from the page query. The page query gets it's data from contentful.
 *
 * On the final submit of the typeform, the typeform will generate a response ID. The
 * response ID is then sent to our backend which is then used as the formula's id. This
 * becomes the reference point for the formula.
 **************************************************************************************/

import React, { useRef, useEffect, useState } from 'react';
import QuizContext from '../AssessmentContext';
import { navigate } from 'gatsby';
import { Widget } from '@typeform/embed-react';
import updateStatus from '../utils/updateStatus';
import Error from '../components/Error';
import { KeyValue, SectionProps } from 'types';

import { SectionContainer2, Container, Box } from 'ui';

import posthog from 'posthog-js';
import { PaymentOutlined } from '@mui/icons-material';

interface TypeformResponse {
  owner: string | null;
  formId?: string;
  responseId: string;
}

interface TypeformQuestionChanged {
  // owner: string | null;
  formId?: string;
  ref: string;
}

interface UtmData {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

const AssessmentSmartGummiesPage = ({ sectionType, designNodes, ...data }: SectionProps) => {

  /**************************************************************************************
   * GET THE STAGE FROM THE URL IF THERE IS ONE
   * ------------------------------------------------------------------------------------
   * The stage may be passed in as a query param. This is used to determine the current
   * pregnancy stage. The stage is used to determine the questions that are presented to
   * the user.
   * ************************************************************************************/
  const [stage, setStage] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // GET STAGE FROM URL QUERY PARAMS
      const searchParams = new URLSearchParams(window.location.search);
      const stage = searchParams.get('stage');
      if (stage) {
        setStage(stage);
      }
    }
  }, []);

  /**************************************************************************************
   * CONTEXT DATA FOR TRACKING AND ANALYTICS
   * ------------------------------------------------------------------------------------
   * Data needed for user tracking comes from some context attributes.
   * The context changes and therefor will trigger a re-render of the component creating
   * a bad user experience. To avoid this, we are storing the data in refs. Note that
   * this also requires passing the context load to a child component that can re-render
   * as the context changes rather than handle the context loading in this component.
   */
  const modeRef = useRef<string>(null);
  const ownerRef = useRef<string>(null);
  const utmDataRef = useRef<UtmData>(null);
  const currentQuestion = useRef('');

  /**************************************************************************************
   * TYPEFORM ID FROM PAGE DATA KEY VALUES OBJECT
   * ------------------------------------------------------------------------------------
   * TODO: Turn this into a shared utility
   * The typeform ID comes from the page query. The page query includes a query for
   * the keyValues object. This object contains key value pairs with some important
   * meta data necessary for selecting the correct key value.
   *
   * The following logic will select the correct key value based on the
   * - Key Type
   * - Environment
   * - Hostname
   *
   * This ensures that the correct Typeform ID is loaded when on
   * Localhost, Dev Environment and Production Environment
   */

  // Get the keyValues object from the page query
  const { keyValues } = data?.data || null;
  if (!keyValues) {
    console.error('No keyValues found');
    return <Error />;
  }

  // Filter the keyValues object to only include the typeform keys
  const keys: KeyValue[] = keyValues.filter((obj: KeyValue) => obj.keyType === 'typeform');
  if (!keys.length) {
    // console.log('No typeform keys found');
    return <Error />;
  }

  // Set the correct config based on Environment and Hostname
  let config: KeyValue | undefined;

  if (
    typeof window !== 'undefined' &&
    (process.env.NODE_ENV === 'development' ||
      window?.location?.hostname === 'localhost' ||
      window?.location?.hostname === 'luminaryvitamins.dev.elo.engineering' ||
      window?.location?.hostname === 'luminaryvitamins.engineering')
  ) {
    config = keys.find(obj => obj.environment === 'development');
  } else {
    config = keys.find(obj => obj.environment === 'production');
  }

  // Uncomment to debug the ID
  // console.log('typeform id', config?.value);

  if (!config) return <Error />;
  const typeformID = config.value;

  /**
   * FILTER QUALIFIED USERS
   * If the user is not qualified, we will need to display a message informing them that they are not qualified
   * The current solution is to look for the "ready" ref, which is the last question in the form
   * the ref value of 'ready' comes from the final question on the typeform form. It's important to note that
   * the value of 'ready' can be changed in typeform but it is hard coded here. If the value of 'ready' changes on the final question
   * then this will break.
   */

  const handleQuestionUpdate = async ({ formId, ref }: TypeformQuestionChanged) => {
    // Comment and un-comment for debugging
    // console.log('=========================================');
    // console.log('Logging from handleQuestionUpdate');
    // console.log('modeRef.current', modeRef.current);
    // console.log('ownerRef.current', ownerRef.current);
    // console.log('utmDataRef.current', utmDataRef.current);
    // console.log('currentQuestion.current', currentQuestion.current);
    // console.log('-----------------------------------------');

    // Set the current question.
    // This is set to a ref value so that the component is not re-rendered based on a state change
    currentQuestion.current = ref;

    if (!formId) return;
    // Post Event to Posthog
    posthog.capture('assessment question changed', {
      form_id: formId,
      current_question: ref,
      changed_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      utm_source: utmDataRef.current?.utm_source,
      utm_medium: utmDataRef.current?.utm_medium,
      utm_campaign: utmDataRef.current?.utm_campaign,
      utm_term: utmDataRef.current?.utm_term,
      utm_content: utmDataRef.current?.utm_content,
    });

    try {
      await updateStatus({
        formId: formId,
        anonymousId: ownerRef.current ?? '',
        updatedAt: new Date().toISOString(),
        currentQuestion: ref,
        targeting: {
          utmSource: utmDataRef.current?.utm_source,
          utmMedium: utmDataRef.current?.utm_medium,
          utmCampaign: utmDataRef.current?.utm_campaign,
          utmTerm: utmDataRef.current?.utm_term,
          utmContent: utmDataRef.current?.utm_content,
        },
      });
      return;
    } catch (error) {
      console.error('Failed to update status', error);
      return;
    }
  };

  /**
   * WHEN THE FORM IS SUBMITTED
   * This is called when the user submits the form. It will navigate the user to the formula page
   * with the formId and responseId as query params.
   *
   * Also update the status of the user to reflect that they have completed the form
   */

  const handleNavigate = async ({ formId, responseId }: TypeformResponse) => {
    /**
     * Disallow navigation if the user is not on the last question
     * the last question is detected by the "ready" ref
     * This is hard coded. If that questions ref value changes then this will break
     */
    if (currentQuestion.current !== 'ready') return;

    if (!formId) return;

    // Set the product and subscription or sub values for the naviation query params used in the try catch block below.
    const product = 'smart-gummy-assessment';
    const sub = 'elo-smart-gummies-quarterly';

    // Post Event to Posthog
    posthog.capture('assessment submitted', {
      form_id: formId,
      response_id: responseId,
      submitted_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      utm_source: utmDataRef.current?.utm_source,
      utm_medium: utmDataRef.current?.utm_medium,
      utm_campaign: utmDataRef.current?.utm_campaign,
      utm_term: utmDataRef.current?.utm_term,
      utm_content: utmDataRef.current?.utm_content,
    });

    try {
      await updateStatus({
        formId: formId,
        anonymousId: ownerRef.current ?? '',
        responseId: responseId,
        updatedAt: new Date().toISOString(),
        completedAt: new Date().toISOString(),
        currentQuestion: 'completed',
        targeting: {
          utmSource: utmDataRef.current?.utm_source,
          utmMedium: utmDataRef.current?.utm_medium,
          utmCampaign: utmDataRef.current?.utm_campaign,
          utmTerm: utmDataRef.current?.utm_term,
          utmContent: utmDataRef.current?.utm_content,
        },
      });

      /**
       * ASSESSMENT SUMMARY PAGE ROUTE
       * This is the route to the assessment summary page. When the user completes the assessment, this is the page they will be routed to.
       * The query params are
       * product: determines which product to load
       * sub: determines which subscription to load
       * formId: identifies the typeform form or asseessment that was completed
       * summary: identifies which assessment summary to load
       *
       * These are used to load the proper products, fetch the formula data from the backend and to use the correct summary.
       */

      await navigate(
        `/cart/assessment-summary/?product=${product}&sub=${sub}&formId=${formId}&responseId=${responseId}`
      );
      return;
    } catch (error) {
      console.error('Failed to update status', error);
      await navigate(
        `/cart/assessment-summary/?product=${product}&sub=${sub}&formId=${formId}&responseId=${responseId}`
      );
      return;
    }
  };

  const handleOnReady = async ({ formId }: { formId: string }) => {
    // Post Event to Posthog
    posthog.capture('assessment started', {
      form_id: formId,
      started_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      utm_source: utmDataRef.current?.utm_source,
      utm_medium: utmDataRef.current?.utm_medium,
      utm_campaign: utmDataRef.current?.utm_campaign,
      utm_term: utmDataRef.current?.utm_term,
      utm_content: utmDataRef.current?.utm_content,
    });
  };

  {
    /**************************************************************************************
     * FUTURE SELF and FUTURE DEVELOPERS
     * ------------------------------------------------------------------------------------
     * <QuizContext /> component is critical to the performance of the quiz page
     * As a child component, it is responsible for updating the mode, owner and utmData
     * that is being used by the parent component. This is offloaded to the child component
     * because the child component is re-rendered every time the context is updated. This
     * prevents the parent component from re-rendering every time the context is updated.
     *
     * NOTE: onReady has been removed from the Widget component. This is because the onReady
     * callback was sending the owner data to anayltics. The owner data is not available until
     * the context is updated. Context will not be updated prior to the widget loading.
     */
  }
  // console.log('RENDERING QUIZ PAGE');

  let formId = typeformID;
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('synthetic-tests') === 'true') {
      formId = 'JzRpwFjj';
    }
  }

  return (
    <>
      <QuizContext modeRef={modeRef} ownerRef={ownerRef} utmDataRef={utmDataRef} />
      <SectionContainer2 sectionType={sectionType} designNodes={designNodes}>
        <Container>
          <Box height={['90vh']} background={'#efefef'}>
            <Widget
              id={formId}
              // autoResize={false}
              // autoResize={'450'}
              // @ts-ignore
              onSubmit={payload => handleNavigate({ ...payload })}
              onReady={payload => handleOnReady({ ...payload })}
              onQuestionChanged={formId => handleQuestionUpdate({ ...formId })}
              className="my-form"
              inlineOnMobile={true} // removes the mobile startup screend
              disableScroll={true} // disables scroll on the form
              // The hidden field is required to pass the product to the typeform
              // This is used by the backend to determine which type of formula to create
              // TODO: This should be added to the CMS rather than hard coded.
              hidden={{
                product: 'gummies',
                user_id: posthog.get_distinct_id(),
                ...(stage && { stage: stage }), // Conditionally include the stage key and value
              }}
              // iframeProps={{
              //   height: '100%',
              // }}
              style={{ height: '100%' }}
            />
          </Box>
        </Container>
      </SectionContainer2>
    </>
  );
};

export default AssessmentSmartGummiesPage;
