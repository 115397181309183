exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-assess-index-tsx": () => import("./../../../src/pages/assess/index.tsx" /* webpackChunkName: "component---src-pages-assess-index-tsx" */),
  "component---src-pages-assessment-tsx": () => import("./../../../src/pages/assessment.tsx" /* webpackChunkName: "component---src-pages-assessment-tsx" */),
  "component---src-pages-cart-assessment-summary-index-tsx": () => import("./../../../src/pages/cart/assessment-summary/index.tsx" /* webpackChunkName: "component---src-pages-cart-assessment-summary-index-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout-success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-copy-tsx": () => import("./../../../src/pages/index copy.tsx" /* webpackChunkName: "component---src-pages-index-copy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ref-tsx": () => import("./../../../src/pages/ref.tsx" /* webpackChunkName: "component---src-pages-ref-tsx" */),
  "component---src-templates-answer-tsx": () => import("./../../../src/templates/answer.tsx" /* webpackChunkName: "component---src-templates-answer-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamicPage.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-v-2-page-tsx": () => import("./../../../src/templates/V2Page.tsx" /* webpackChunkName: "component---src-templates-v-2-page-tsx" */)
}

